<template>
    <div class="card bs-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="title"></h4>

            <div>
                <btn v-if="transaction !== 'pending'" text="Backup" icon="fa fa-download" :loading="downloading"
                     loading-text="Downloading.." @click="downloadBackup" />
            </div>
        </div>
        <vue-table :fields="fields" :url="url" :per-page="10" ref="table" />
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'TransactionList',
    data () {
        return {
            title       : '',
            downloading : false,
            loading     : true,
            error       : false,
            fields      : [
                {
                    name       : 'transaction_id',
                    sortField  : 'transaction_id',
                    title      : 'Transaction ID',
                    titleClass : ''
                },
                {
                    name       : 'phone_number',
                    sortField  : 'phone_number',
                    title      : 'Phone',
                    titleClass : ''
                },
                {
                    name       : 'initiated_date_new',
                    sortField  : 'initiated_date_new',
                    title      : 'Initiated Date',
                    titleClass : ''
                },
                {
                    name       : 'amount',
                    sortField  : 'amount',
                    title      : 'Amount',
                    titleClass : ''
                },
                {
                    name       : 'status',
                    sortField  : 'status',
                    title      : 'Status',
                    titleClass : ''
                },
                {
                    name       : 'transaction_mode',
                    sortField  : 'transaction_mode',
                    title      : 'Transaction Mode',
                    titleClass : ''
                }
            ]
        };
    },

    computed : {
        transaction () {
            return this.$route.params.type;
        },

        url () {
            const name = this.transaction;
            if (name === 'pending') {
                this.setTitle('pending');
                return urls.transactions.transactionList + '?type=pending';
            } else if (name === 'success') {
                this.setTitle('success');
                return urls.transactions.transactionList + '?type=success';
            } else {
                this.setTitle('failed');
                return urls.transactions.transactionList + '?type=failed';
            }
        }
    },
    methods : {
        setTitle (name) {
            if (name === 'pending') {
                this.title = 'TRANSACTION PENDING';
            } else if (name === 'success') {
                this.title = 'TRANSACTION SUCCESS';
            } else {
                this.title = 'TRANSACTION FAILURE';
            }
        },

        downloadBackup () {
            const that = this;

            that.downloading = true;

            axios.form(urls.backup.transaction, { type : that.transaction }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.downloading = false;
                    window.open(json.url);
                } else {
                    that.downloading = false;
                    that.$notify('', json.message, {
                        type : 'warning'
                    });
                }
            }).catch(function (exception) {
                that.downloading = false;
                console.log('exception occurred at backup : ', exception);
                that.$notify('Something went wrong..!!', 'Error', {
                    type : 'warning'
                });
            });
        }
    }
};
</script>

<style scoped>

</style>
